import React from "react";
const HomeIndex = React.lazy(() =>
  import(/* webpackChunkName: "HomeIndex" */ "./pages/home/index")
);

const LoginIndex = React.lazy(() =>
  import(/* webpackChunkName: "LoginIndex" */ "./pages/user/login")
);

const OutletIndex = React.lazy(() =>
  import(/* webpackChunkName: "OutletIndex" */ "./pages/outlet/index")
);

const OutletDetail = React.lazy(() =>
  import(/* webpackChunkName: "OutletDetail" */ "./pages/outlet/detail")
);

const OutletBatchPosm = React.lazy(() =>
  import(
    /* webpackChunkName: "OutletBatchPosm" */ "./pages/outlet/batch-customized"
  )
);

const OutletSinglePosm = React.lazy(() =>
  import(
    /* webpackChunkName: "OutletBatchPosm" */ "./pages/outlet/single-customized"
  )
);

const OutletServicePosm = React.lazy(() =>
  import(
    /* webpackChunkName: "OutletServicePosm" */ "./pages/outlet/service-customized"
  )
);

const OutletEditService = React.lazy(() =>
  import(
    /* webpackChunkName: "OutletEditService" */ "./pages/outlet/service-customized/edit"
  )
);

const OrderList = React.lazy(() =>
  import(/* webpackChunkName: "OrderList" */ "./pages/order/list/index")
);
const OrderProgressList = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderProgressList" */ "./pages/order/list/progress"
  )
);

const OrderDetail = React.lazy(() =>
  import(/* webpackChunkName: "OrderDetail" */ "./pages/order/detail/index")
);

const OrderServiceDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderServiceDetail" */ "./pages/order/detail/service-index"
  )
);

const OrderSubServiceDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderSubServiceDetail" */ "./pages/order/detail/sub-service-index"
  )
);

const OrderAuditList = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAuditList" */ "./pages/order/list/audit/index"
  )
);

const OrderAuditDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAuditDetail" */ "./pages/order/detail/audit/posm-customized"
  )
);

const OrderAllocSingle = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAllocSingle" */ "./pages/order/detail/alloc/single"
  )
);

const OrderAllocService = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAllocService" */ "./pages/order/detail/alloc/service"
  )
);

const OrderAllocSingleSuccess = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAllocSingleSuccess" */ "./pages/order/detail/alloc/single/success"
  )
);

const OrderAllocBatch = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAllocBatch" */ "./pages/order/detail/alloc/batch"
  )
);

const OrderAllocBatchSuccess = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAllocBatchSuccess" */ "./pages/order/detail/alloc/batch/success"
  )
);

const OrderUploadCostDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderUploadCost" */ "./pages/order/detail/cost-upload/index"
  )
);

const OrderAuditQuoteDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderUploadCost" */ "./pages/order/detail/audit-quote"
  )
);

const OrderViewQuoteDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderViewQuoteDetail" */ "./pages/order/detail/quote-detail"
  )
);

/**
 * 完工验收详情
 */
const OrderAcceptanceDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAcceptanceDetail" */ "./pages/order/detail/audit-acceptance"
  )
);

/**
 * 完工验收上传操作
 */
const OrderAcceptanceOperation = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAcceptanceOperation" */ "./pages/order/detail/audit-acceptance/operate"
  )
);

/**
 * 完工验收销售确认操作
 */
const OrderAcceptanceOperationSale = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAcceptanceOperation" */ "./pages/order/detail/audit-acceptance/operate-sale"
  )
);

const NoticeIndex = React.lazy(() =>
  import(/* webpackChunkName: "NoticeIndex" */ "./pages/notice")
);

const ProjectIndex = React.lazy(() =>
  import(/* webpackChunkName: "ProjectIndex" */ "./pages/project")
);

const Common404Index = React.lazy(() =>
  import(/* webpackChunkName: "Common404Index" */ "./pages/error/404")
);

const Common400Index = React.lazy(() =>
  import(/* webpackChunkName: "Common400Index" */ "./pages/error/400")
);

const Common403Index = React.lazy(() =>
  import(/* webpackChunkName: "Common400Index" */ "./pages/error/403")
);

const offerIndex = React.lazy(() =>
  import(/* webpackChunkName: "offerIndex" */ "./pages/offer")
);

const acceptanceIndex = React.lazy(() =>
  import(/* webpackChunkName: "acceptanceIndex" */ "./pages/acceptance")
);

const OrderHistoryOrder = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderHistoryOrder" */ "./pages/order/history-order"
  )
);

const OrderAuditDemand = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderAuditDemand" */ "./pages/order/detail/audit-demand"
  )
);

const AcceptanceDetail = React.lazy(() =>
  import(/* webpackChunkName: "AcceptanceDetail" */ "./pages/acceptance-detail")
);

const ServiceSaveSuccess = React.lazy(() =>
  import(
    /* webpackChunkName: "ServiceSaveSuccess" */ "./pages/outlet/service-customized/success"
  )
);

const GuideIndex = React.lazy(() =>
  import(/* webpackChunkName: "GuideIndex" */ "./pages/guide")
);

const HomeOrderMessageRemind = React.lazy(() =>
  import(
    /* webpackChunkName: "HomeOrderMessageRemind" */ "./pages/home/order-message-remind"
  )
);

// ---------报表 start---------
const StatementIndex = React.lazy(() =>
  import(/* webpackChunkName: "StatementIndex" */ "./pages/statement")
);
const StatementClientDetailProgressTrack = React.lazy(() =>
  import(
    /* webpackChunkName: "StatementClientDetailProgressTrack" */ "./pages/statement/client-detail-progress-track"
  )
);
const StatementClientTargetFinishTrack = React.lazy(() =>
  import(
    /* webpackChunkName: "StatementClientTargetFinishTrack" */ "./pages/statement/client-target-finish-track"
  )
);
const StatementProjectBudgetTrack = React.lazy(() =>
  import(
    /* webpackChunkName: "StatementProjectBudgetTrack" */ "./pages/statement/project-budget-track"
  )
);
const StatementSupplierAccountDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "StatementSupplierAccountDetail" */ "./pages/statement/supplier-account-detail"
  )
);
const StatementCustomizedGallery = React.lazy(() =>
  import(
    /* webpackChunkName: "StatementCustomizedGallery" */ "./pages/statement/customized-gallery"
  )
);

const SamplingDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "SamplingDetails" */ "./pages/statement/sampling-details"
  )
);

// ---------报表 end---------

const OrderReportPoAcceptance = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderReportPoAcceptance" */ "./pages/order/order-report/po-acceptance"
  )
);

// --------- PO验收单---------
const PoAcceptanceOrder = React.lazy(() =>
  import(/* webpackChunkName: "PoAcceptanceOrder" */ "./pages/po-acceptance")
);

//---------抽检--------

const SamplingList = React.lazy(() =>
  import(/* webpackChunkName: "SamplingList" */ "./pages/order/list/sampling")
);

const SamplingAuditList = React.lazy(() =>
  import(
    /* webpackChunkName: "SamplingAuditList" */ "./pages/order/list/sampling/audit"
  )
);

const AsyncDownload = React.lazy(() =>
  import(/* webpackChunkName: "SamplingAuditList" */ "./pages/async-download")
);

export const routes = [
  {
    path: "/async-download",
    component: AsyncDownload,
  },
  {
    path: "/sampling/list/audit/:type/:status",
    component: SamplingAuditList,
  },
  {
    path: "/sampling/list/audit/:type",
    component: SamplingAuditList,
  },
  {
    path: "/sampling/list/:type",
    component: SamplingList,
  },
  {
    path: "/sampling/list/:status/:type",
    component: SamplingList,
  },
  {
    path: "/acceptance/detail/:type/:orderCode/:code",
    component: AcceptanceDetail,
  },
  {
    path: "/order/history-order",
    component: OrderHistoryOrder,
  },
  {
    path: "/order/detail/audit-demand/:code",
    component: OrderAuditDemand,
  },
  {
    path: "/order/detail/audit-acceptance/:type/:code/operate/:acceptanceCode",
    component: OrderAcceptanceOperationSale,
  },
  {
    path: "/order/detail/alloc/single/:code",
    component: OrderAllocSingle,
  },
  {
    path: "/order/detail/alloc/service/:code",
    component: OrderAllocService,
  },

  {
    path: "/order/detail/alloc/single/success",
    component: OrderAllocSingleSuccess,
  },
  {
    path: "/order/detail/alloc/batch/:code",
    component: OrderAllocBatch,
  },
  {
    path: "/order/detail/alloc/batch/success",
    component: OrderAllocBatchSuccess,
  },
  {
    path: "/order/detail/audit-acceptance/:type/:code",
    component: OrderAcceptanceDetail,
  },
  {
    path: "/order/detail/audit-acceptance/:type/:code/operate",
    component: OrderAcceptanceOperation,
  },
  {
    path: "/order/detail/quote-detail/:type/:code", //报价详情
    component: OrderViewQuoteDetail,
  },
  {
    path: "/order/detail/upload-cost/:type/:code",
    component: OrderUploadCostDetail,
  },
  {
    path: "/order/detail/audit-quote/:type/:code",
    component: OrderAuditQuoteDetail,
  },
  {
    path: "/order/detail/audit/:type/:code",
    component: OrderAuditDetail,
  },
  {
    path: "/order/list/audit/:type/:status/:rangeCode", //子状态
    component: OrderAuditList,
  },
  {
    path: "/order/list/audit/:type/:status",
    component: OrderAuditList,
  },
  {
    path: "/order/detail/service/:code",
    component: OrderServiceDetail,
  },
  {
    path: "/order/detail/service/sub/:code/:tabIndex",
    component: OrderSubServiceDetail,
  },
  {
    path: "/order/detail/service/sub/:code",
    component: OrderSubServiceDetail,
  },
  {
    path: "/order/detail/:code/:tabIndex",
    component: OrderDetail,
  },
  {
    path: "/order/detail/:code",
    component: OrderDetail,
  },
  // {
  //   path: "/order/:type/:rangeCode/my/:me",
  //   component: OrderList,
  // },
  // {
  //   path: "/order/:fromType/:type/:rangeCode/:timeType",
  //   component: OrderList,
  // },
  {
    path: "/order/progress/:isMe/:type/:rangeCode",
    component: OrderProgressList,
  },
  {
    path: "/order/progress/:isMe/:type/:rangeCode/:timeType",
    component: OrderProgressList,
  },
  {
    path: "/order/:type/:rangeCode/:isMain",
    component: OrderList,
  },
  {
    path: "/order/:type/:rangeCode",
    component: OrderList,
  },

  {
    path: "/order/:type",
    component: OrderList,
  },
  {
    path: "/order",
    component: OrderList,
  },
  {
    path: "/outlet",
    component: OutletIndex,
  },
  {
    path: "/outlet/detail/:outletCode",
    component: OutletDetail,
  },
  {
    path: "/user/login",
    component: LoginIndex,
  },
  {
    path: "/",
    component: HomeIndex,
  },
  {
    path: "/outlet/service-customized/save/success",
    component: ServiceSaveSuccess,
  },
  {
    path: "/outlet/service-customized/:step/main/edit/:code",
    component: OutletServicePosm,
  },
  {
    path: "/outlet/service-customized/:step/edit/:code",
    component: OutletEditService,
  },
  {
    path: "/outlet/service-customized/:step",
    component: OutletServicePosm,
  },
  {
    path: "/outlet/single-customized/:step/:outletCode",
    component: OutletSinglePosm,
  },
  {
    path: "/outlet/single-customized/:step/:outletCode/edit/:code",
    component: OutletSinglePosm,
  },
  {
    path: "/outlet/batch-customized/:step",
    component: OutletBatchPosm,
  },
  {
    path: "/outlet/batch-customized/:step/edit/:uuid",
    component: OutletBatchPosm,
  },
  {
    path: "/notice",
    component: NoticeIndex,
  },
  {
    path: "/notice/:type",
    component: NoticeIndex,
  },
  {
    path: "/project",
    component: ProjectIndex,
  },
  {
    path: "/project/detail/:uuid",
    component: ProjectIndex,
  },
  {
    path: "/offer/:type/:rangeCode",
    component: offerIndex,
  },
  {
    path: "/acceptance/:type/:rangeCode",
    component: acceptanceIndex,
  },
  {
    path: "/guide",
    component: GuideIndex,
  },
  {
    path: "/statement",
    component: StatementIndex,
  },
  {
    path: "/statement/client-detail-progress-track",
    component: StatementClientDetailProgressTrack,
  },
  {
    path: "/statement/client-target-finish-track",
    component: StatementClientTargetFinishTrack,
  },
  {
    path: "/statement/project-budget-track",
    component: StatementProjectBudgetTrack,
  },
  {
    path: "/statement/supplier-account-detail",
    component: StatementSupplierAccountDetail,
  },
  {
    path: "/statement/customized-gallery",
    component: StatementCustomizedGallery,
  },
  {
    path: "/statement/sampling-details",
    component: SamplingDetails,
  },

  {
    path: "/home/order-message-remind",
    component: HomeOrderMessageRemind,
  },
  {
    path: "/order/report/po-acceptance/:type/:code",
    component: OrderReportPoAcceptance,
  },
  {
    path: "/po-acceptance/:type/:rangeCode",
    component: PoAcceptanceOrder,
  },
  {
    path: "/400",
    component: Common400Index,
  },
  {
    path: "/403",
    component: Common403Index,
  },
  {
    path: "/*",
    component: Common404Index,
  },
];
